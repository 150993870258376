var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-2",attrs:{"flat":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',{staticClass:"d-flex"},[_c('v-row',[_c('v-col',{attrs:{"c":"","cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.reglas.nombre,"label":'Nombre',"required":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.datos.nombre),callback:function ($$v) {_vm.$set(_vm.datos, "nombre", $$v)},expression:"datos.nombre"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"3","dense":"","outlined":"","label":"Descripcion","rules":_vm.reglas.descripcion},model:{value:(_vm.datos.descripcion),callback:function ($$v) {_vm.$set(_vm.datos, "descripcion", $$v)},expression:"datos.descripcion"}})],1),_c('v-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('DatePicher',{attrs:{"label":'Fecha Inicio',"rules":_vm.reglas.fecha,"dense":true,"outlined":true},model:{value:(_vm.datos.fecha_inicio),callback:function ($$v) {_vm.$set(_vm.datos, "fecha_inicio", $$v)},expression:"datos.fecha_inicio"}})],1),_c('v-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('select-moneda',{attrs:{"rules":_vm.reglas.moneda,"dense":true,"outlined":true},model:{value:(_vm.datos.id_moneda),callback:function ($$v) {_vm.$set(_vm.datos, "id_moneda", $$v)},expression:"datos.id_moneda"}})],1),_c('v-col',{attrs:{"lg":"4","md":"6","cols":"12"}},[_c('v-text-field-money',{attrs:{"label":'Saldo inicial',"properties":{
              prefix: _vm.moneda.simbolo,
              readonly: false,
              disabled: false,
              outlined: true,
              clearable: true,
              persistentHint: true,
              dense: true,
              rules: _vm.reglas.saldo_inicial,
            },"options":{
              locale: 'es-Ve',
              length: 11,
              precision: 2,
              empty: null,
            }},model:{value:(_vm.datos.saldo_inicial),callback:function ($$v) {_vm.$set(_vm.datos, "saldo_inicial", $$v)},expression:"datos.saldo_inicial"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"mb-4"}),(_vm.datos.id > 0)?_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.resetear()}}},[_vm._v(" Resetear ")]):_vm._e(),_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.limpiar()}}},[_vm._v(" Limpiar ")]),_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.GuardarCaja()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }