
<template>
  <div>
    <v-card>
      <v-card-title
        >Lista Cajas

        <v-btn icon @click="cargarCajas()" color="green" :loading="cargandoDatos" :disabled="cargandoDatos">
          <v-icon>{{ icons.mdiCached }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" offset-md="8" md="4">
            <v-text-field
              v-model="search"
              :append-icon="icons.mdiMagnify"
              label="Buscar"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        :headers="headers"
        :items="usuarioList"
        :search="search"
        :items-per-page="5"
        :loading="usuarioListLoad"
        class="table-kitchen-sink"
      >
        <template #[`item.accion`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on" @click="GetEditarCaja(item)">
                <v-icon>{{ icons.mdiPencil }}</v-icon>
              </a>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <a class="pr-2" v-bind="attrs" v-on="on">
                <v-icon>{{ item.estado == 5 ? icons.mdiCancel : icons.mdiCheck }}</v-icon>
              </a>
            </template>
            <span>{{ item.estado == 5 ? 'Inctivar' : 'Activar' }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
  
<script>
import { onBeforeMount, ref, context } from '@vue/composition-api'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiStoreEdit,
  mdiPencilOutline,
  mdiAccountCancel,
  mdiAccountEdit,
  mdiAccountCheck,
  mdiCheck,
  mdiCancel,
  mdiStoreEditOutline,
  mdiPencil,
  mdiCached,
} from '@mdi/js'
import goTo from 'vuetify/lib/services/goto'
import config from '@/api/config'
import CuentasService from '@/api/servicios/CuentasService'

export default {
  setup(props, context) {
    const search = ref('')
    const usuarioList = ref([])
    const usuarioListLoad = ref(false)
    const cargandoDatos = ref(false)
    const GetEditarCaja = item => {
      context.emit('GetEditarcaja', { ...item })
      goTo(0)
    }
    onBeforeMount(() => {
      cargarCajas()
    })

    const cargarCajas = () => {
      cargandoDatos.value = true
      CuentasService.cajaListar()
        .then(response => {
          console.log(response)
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            usuarioList.value = response.data.datos
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarCajas',
          })
        })
        .finally(() => {
          cargandoDatos.value = false
        })
    }

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountCancel,
        mdiAccountEdit,
        mdiAccountCheck,
        mdiCheck,
        mdiCancel,
        mdiStoreEdit,
        mdiStoreEditOutline,
        mdiPencil,
        mdiCached,
      },
      search,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'ACCION', value: 'accion', sortable: false },
      ],
      usuarioList,
      usuarioListLoad,
      GetEditarCaja,
      cargarCajas,
      config,
      cargandoDatos,
    }
  },
}
</script>
  
<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
  