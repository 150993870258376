<template>
  <div>
    <v-container>
      <v-row>
        <v-col lg="12" cols="12">
          <v-card>
            <v-card-title>Caja</v-card-title>
            <v-card-text>
              <caja-nuevo @CargarLista="CargarLista" :CajaDatos="caja"></caja-nuevo>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col lg="12" cols="12">
          <caja-lista ref="cajaListaRef" @GetEditarcaja="GetEditarcaja"></caja-lista>
        </v-col> 
      </v-row>
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import CajaNuevo from './components/CajaNuevo.vue'
import { ref } from '@vue/composition-api'
import CajaLista from './components/CajaLista.vue'
import moment from 'moment'
// demos

export default {
  components: { CajaLista, CajaNuevo },
  setup() {
    const caja = ref({
      id: -1,
      nombre: '',
      descripcion: '',
      saldo_inicial: '',
      fecha_inicio: moment().format('YYYY-MM-DD'),
      id_moneda: null,
    })
    const cajaListaRef = ref(null)
    const GetEditarcaja = data => {
    
      caja.value = {
        ...data
      }

      
    }

    const CargarLista = () => {
      cajaListaRef.value.cargarCajas()
    }
    return {
      caja,
      CargarLista,
      GetEditarcaja,
      cajaListaRef,
    }
  },
}
</script>
