<template>
  <v-card flat class="mt-2">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card-text class="d-flex">
        <v-row>
          <v-col c cols="12">
            <v-text-field
              :rules="reglas.nombre"
              v-model="datos.nombre"
              :label="'Nombre'"
              required
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              rows="3"
              dense
              outlined
              label="Descripcion"
              v-model="datos.descripcion"
              :rules="reglas.descripcion"
            ></v-textarea>
          </v-col>
          <v-col lg="4" md="6" cols="12">
                <DatePicher :label="'Fecha Inicio'"   :rules="reglas.fecha" v-model="datos.fecha_inicio" :dense="true" :outlined="true" />
          </v-col> 
          
          <v-col lg="4" md="6" cols="12">
            <select-moneda :rules="reglas.moneda" v-model="datos.id_moneda" :dense="true" :outlined="true"></select-moneda>
          </v-col>
          <v-col lg="4" md="6" cols="12">
            <v-text-field-money
              v-model="datos.saldo_inicial"
              v-bind:label="'Saldo inicial'"
              v-bind:properties="{
                prefix: moneda.simbolo,
                readonly: false,
                disabled: false,
                outlined: true,
                clearable: true,
                persistentHint: true,
                dense: true,
                rules: reglas.saldo_inicial,
              }"
              v-bind:options="{
                locale: 'es-Ve',
                length: 11,
                precision: 2,
                empty: null,
              }"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-divider class="mb-4"></v-divider>
            <v-btn small v-if="datos.id > 0" color="secondary" class="me-3" @click="resetear()"> Resetear </v-btn>
            <v-btn small color="secondary" class="me-3" @click="limpiar()"> Limpiar </v-btn>
            <v-btn small color="primary" class="me-3" @click="GuardarCaja()"> Guardar </v-btn>
          </v-col>
        </v-row>
      
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCamera, mdiCloudSearch } from '@mdi/js'
import store from '@/store'
import config from '@/api/config'
import CuentasService from '@/api/servicios/CuentasService'
import SelectMoneda from '@/components/SelectMonedaValor.vue'
import DatePicher from '@/components/DatePicker.vue'
import moment from 'moment'
export default {
  components: { SelectMoneda,DatePicher },
  props: {
    CajaDatos: Object,
  },
   
  setup(props, context) {
    const valid = ref(true)
    const datos = ref(JSON.parse(JSON.stringify(props.CajaDatos)))
    const modal = ref(false)
    const form = ref()
    const cargando = ref(false)
    const moneda = ref({})

  

    onBeforeMount(() => {})

    const reglas = {
      id: -1,

      nombre: [
        v => !!v || 'Nombre es requerido',
        v => (v && v.length <= 50 && v.length >= 3) || 'Nombre debe ser valido',
      ],
      descripcion: [
        v => !!v || 'descripcion es requerido',
        v => (v && v.length <= 500 && v.length >= 3) || 'descripcion debe ser valido',
      ],
      fecha: [v => !!v || 'fecha es requerido'],
      saldo_inicial: [v => !!v || 'saldo es requerido'],
      moneda: [v => !!v || 'moneda es requerido'],
    }

    const validarForm = () => {
      let val = form.value?.validate()

      return val
    }

    const limpiar = () => {
      datos.value = {
        id: -1,
        nombre: '',
        descripcion: '',
        fecha_inicio:  moment().format('YYYY-MM-DD'),
        saldo_inicial: '',
        id_moneda: null,
      }
      form.value.resetValidation()
    }

    const GuardarCaja = async () => {
      try {
        if (validarForm()) {
          cargando.value = true
          const parametros = {
            ...datos.value,
            id_empleado: store.state.user.id_empleado,
          }
          CuentasService.nuevoCaja(parametros)
            .then(response => {
              if (response.data.mensaje == 'GUARDADA CON EXITO') {
                console.log(response.data)
                limpiar()
                context.emit('CargarLista')
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
              } else {
                console.log(response.data)
                store.commit('setAlert', {
                  message: 'UPS, ' + response.data.mensaje,
                  type: 'info',
                })
              }
            })
            .catch(err => {
              console.error(err)
              store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'guardarCajas',
          })
            })
            .finally(() => {
              cargando.value = false
            })
        } else {
          store.commit('setAlert', {
            message: 'Verifique que todos los datos estén completos',
            type: 'warning',
          })
        }
      } catch (error) {
        store.commit('setAlert', {
          message: error,
          type: 'error',
          error: error,
            funcion: 'GuardarCaja',
        })
        cargando.value = false
      }
    }
    const getMoneda = item => { 
      moneda.value = item
      datos.value.id_moneda = item.id
    }

    watch(props, () => { 
      resetear()
      
    })

    const resetear = () => {
      datos.value = JSON.parse(JSON.stringify(props.CajaDatos))

      form.value.resetValidation()
    }
    return {
      icons: {
        mdiCloudSearch,
      },
      valid,
      datos,
      reglas,
      GuardarCaja,
      form,
      limpiar,
      config,
      resetear,
      cargando,
      modal,
      getMoneda,
      moneda,
    }
  },
}
</script>